<style scoped>
@import "../assets/css/about.scss";
</style>
<template>
  <div id="about__container">
    <div id="root">
      <!-- Lnacarla grid Section -->
      <section
        id="about"
        class="about__section"
        data-bgcolor="#ffffff"
        style="
          z-index: 0;
          overflow: hidden;
          display: grid;
          margin-top: -1px;
          position: relative;
          align-items: center;
        "
      >
        <div
          id="XnuPwRzSoiJENXiv"
          style="
            transform: scale(1, 1);
            overflow: hidden;
            width: 100%;
            opacity: 1;
            height: 100%;
            top: 0%;
            left: 0%;
            position: absolute;
          "
        ></div>
        <div id="hwvaY53QFGUJUHGP">
          <div id="cuorvoPYtE1hHV1U" style="pointer-events: none">
            <div
              id="B6v9An8Tki8zbMOW"
              style="padding-top: 119.41386527%; transform: rotate(0deg)"
            >
              <div
                id="WY86ZZTR4NNdbMtp"
                style="
                  top: 0px;
                  left: 0px;
                  position: absolute;
                "
              >
                <div
                  id="fDdQ4ILyMmtPVC3d"
                  style="width: 100%; opacity: 1; height: 100%"
                >
                  <div
                    id="IeEV3hjLDhZJ2q1t"
                    style="
                      transform: scale(1, 1);
                      overflow: hidden;
                      width: 100%;
                      height: 103%;
                    "
                  >
                    <div
                      id="yYLxlVmAhf3tCIpI"
                      style="
                        transform: rotate(0deg);
                        top: -1%;
                        position: relative;
                        opacity: 1;
                        left: 1.6em;
                        "
                    >
                    
                      <img
                        :src="getImgUrlImageAbout('lnacarla_grid.jpeg')"
                        class="about--picture"
                        alt="Man Holding Black Dslr Camera"
                        loading="lazy"
                        style="width: 80%; height: 100%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="ptlgDbdSYQwJk0H1" style="pointer-events: none">
            <div
              id="lTwpJO5ZxfqWkcwx"
              style="
                transform: rotate(0deg);
                width: 100%;
                box-sizing: border-box;
                height: 100%;
              "
            >
              <div
                id="WfSuR6iA4N7upIWG"
                style="
                  flex-direction: column;
                  display: flex;
                  width: 100%;
                  justify-content: flex-start;
                  opacity: 1;
                  height: 100%;
                "
              >
                <p
                  id="caYGM7Ivb7RbK0D9"
                  class="about__p"
                  style="
                    text-transform: uppercase;
                    color: #000000;
                    font-family: YAEsdklG4-k-0;
                    line-height: 0.87em;
                  "
                >
                  <span id="prjv0ezwr80WFOxs" style="color: #000000;
                                                     opacity:0;"
                    >About</span
                  >
                </p>
                <p
                  id="zHMvMZFl5thPRl7a"
                  class="about__p"
                  style="
                    text-transform: uppercase;
                    color: #000000;
                    letter-spacing: 0em;
                    font-family: YAEsdklG4-k-0;
                    line-height: 0.87em;
                  "
                >
                  <span id="QUlUUmWnumAiQ7ml" style="color: #000000">me</span
                  ><br />
                </p>
              </div>
            </div>
          </div>
          <div id="s3jrywHew0YfbQ18" style="pointer-events: none">
            <div
              id="oaBswLIXAuqo0Ao1"
              style="
                transform: rotate(0deg);
                box-sizing: border-box;
              "
            >
              <div
                id="plY2DjjCl4zNrmHN"
                style="
                  flex-direction: column;
                  display: flex;
                  width: 100%;
                  justify-content: flex-end;
                  opacity: 1;
                  height: 100%;
                "
              >
                <p
                  id="UGTuZFCuvi5QoI4t"
                  class="about__p"
                  style="
                    margin-left: 0em;
                    text-transform: none;
                    color: #000000;
                    letter-spacing: 0em;
                    font-family: YACkoFYrTFU-0;
                    line-height: 1.5em;
                    direction: ltr;
                  "
                >
                  <span
                    id="mHGXjb9vMq5qRt97"
                    style="
                      text-decoration-line: none;
                      color: #000000;
                      font-weight: 400;
                      font-style: normal;
                      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
                    "
                    >There are emotions in dance and photography,
                     which combined together, reveal a powerful and striking universe.
                     Behind the lens LNACARLA seizes the opportunity,
                     the capture of emotions, those that will tell a story.
                  </span>
                </p>
                <p
                  id="UGTuZFCuvi5QoI4t"
                  class="about__p"
                  style="
                    margin-left: 0em;
                    text-transform: none;
                    color: #000000;
                    letter-spacing: 0em;
                    font-family: YACkoFYrTFU-0;
                    line-height: 1.5em;
                    direction: ltr;
                  "
                >
                  <span
                    id="mHGXjb9vMq5qRt97"
                    style="
                      text-decoration-line: none;
                      color: #000000;
                      font-weight: 400;
                      font-style: normal;
                      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;

                    "
                    >
                      She specializes in movement, the unexpected and the human.
                      She associates her work with the hashtag #ouvrezlesyeux
                      to encourage the world to open its eyes to what it really is.
                  </span>
                  <span
                    id="mHGXjb9vMq5qRt97"
                    style="
                      text-decoration-line: none;
                      color: #000000;
                      font-weight: 800;
                      font-style: normal;
                      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;

                    "
                    >
                      BEAUTIFUL. SINGULAR. POWERFUL.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Get in Touch Section -->
      <section
        id="page-1"
        class="about__section  about__getInTouch"
        data-bgcolor="#000000"
        style="
          z-index: 0;
          overflow: hidden;
          display: grid;
          margin-top: -1px;
          position: relative;
          align-items: center;
        "
      >
        <div
          id="dflWUKTaxd2zf0it"
          style="
            transform: scale(1, 1);
            overflow: hidden;
            width: 100%;
            opacity: 1;
            height: 100%;
            top: 0%;
            left: 0%;
            position: absolute;
          "
        ></div>
        <div id="HZ9r358dbjK1XPKv">
          <div id="QSNjgR9Xn8nAafbg" style="pointer-events: none">
            <div
              id="xz3xqmIH46GmdcOK"
              style="
                transform: rotate(0deg);
                width: 100%;
                box-sizing: border-box;
                height: 100%;
              "
            >
              <div
                id="sMXh4sxCPV2st77d"
                style="
                  flex-direction: column;
                  display: flex;
                  width: 100%;
                  justify-content: flex-start;
                  opacity: 1;
                  height: 100%;
                "
              >
                <p
                  id="zw9CiMjUDoZhIC5A"
                  class="about__p"
                  style="
                    margin-left: 0em;
                    text-transform: uppercase;
                    color: #edeced;
                    letter-spacing: 0em;
                    font-family: YAEsdklG4-k-0;
                    line-height: 0.87em;
                    direction: ltr;
                  "
                >
                  <span
                    id="h2ObmF8fV44tLK8o"
                    style="
                      text-decoration-line: none;
                      color: #edeced;
                      font-weight: 400;
                      font-style: normal;
                    "
                    >Get in touch</span
                  >
                </p>
              </div>
            </div>
          </div>
          <!-- <div id="MS7GeoKgQoSgJHRh" style="pointer-events: none">
            <div
              id="lKtthfc3f51v1dsR"
              style="
                transform: rotate(0deg);
                width: 100%;
                box-sizing: border-box;
                height: 100%;
              "
            >
              <div
                id="ZTpDKjM8pDeIVlr8"
                style="
                  flex-direction: column;
                  display: flex;
                  width: 100%;
                  justify-content: flex-end;
                  opacity: 1;
                  height: 100%;
                "
              >
                <p
                  id="jfvpQySK7ZI5j3ih"
                  class="about__p"
                  style="
                    margin-left: 0em;
                    text-transform: none;
                    color: #edeced;
                    letter-spacing: 0em;
                    font-family: YAD_cUhqUyg-0;
                    line-height: 1.2em;
                    direction: ltr;
                  "
                >
                  <span
                    id="PlpR8pQ6UsrgRvRY"
                    style="
                      text-decoration-line: none;
                      color: #edeced;
                      font-weight: 400;
                      font-style: normal;
                      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
                    "
                    >Email :
                  </span>
                </p>
              </div>
            </div>
          </div> -->
          <div id="N7p3muzqjrwOUZb1" style="pointer-events: none">
            <div
              id="lp3wiagp6RxT4qgl"
              style="
                transform: rotate(0deg);
                width: 100%;
                box-sizing: border-box;
                height: 100%;
              "
            >
              <div
                id="HeZdTmi18ELfs3iT"
                style="
                  flex-direction: column;
                  display: flex;
                  width: 100%;
                  justify-content: flex-end;
                  opacity: 1;
                  height: 100%;
                "
              >
                <p
                  id="wgvIa8Qq4p2fKFFB"
                  class="about__p"
                  style="
                    margin-left: 0em;
                    text-transform: none;
                    color: #edeced;
                    letter-spacing: 0em;
                    font-family: YACkoFYrTFU-0;
                    line-height: 1.2em;
                    direction: ltr;
                  "
                >
                  <span
                    id="aimwlcqYkZVvYsl9"
                    style="
                      text-decoration-line: none;
                      color: #edeced;
                      font-weight: 400;
                      font-style: normal;
                      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
                    "
                    >lnacarla.photography@gmail.com</span
                  >
                </p>
              </div>
            </div>
          </div>
          <div id="RzKmwP4BCMoYCc4H" style="pointer-events: none">
            <div
              id="b8L8RDXYD6sEXwzN"
              style="
                transform: rotate(0deg);
                width: 100%;
                box-sizing: border-box;
                height: 100%;
              "
            >
            </div>
          </div>
          <div id="FDoeLntwAGQwQfLY" style="pointer-events: none">
            <div
              id="wY2hIlDaj2jbs9Lp"
              style="padding-top: 124.89319064%; transform: rotate(0deg)"
            >
              <div
                id="Gi6PptIKYmodQZQK"
                style="
                  top: 0px;
                  left: 0px;
                  width: 100%;
                  position: absolute;
                  height: 100%;
                "
              >
                <div
                  id="wx0pyMVvImmGOHK3"
                  style="width: 100%;
                         opacity: 1;
                         height: 100%"
                >
                  <div
                    id="LRR4OFckHVYORGNB"
                    style="
                      transform: scale(1, 1);
                      overflow: hidden;
                      width: 100%;
                      height: 100%;
                    "
                  >
                    <div
                      id="kLou3YLLiJAtWhR7"
                      style="
                        transform: rotate(0deg);
                        top: 0%;
                        left: -30.116%;
                        width: 166.617%;
                        position: relative;
                        opacity: 1;
                        height: 100%;
                      "
                    >
                      <img
                        :src="getImgUrlImageAbout('lnacarla_get_in_touch.jpg')"
                        class="about--picture"
                        alt="Aerial Photography Of Boat In The Middle Of Body Of Water"
                        loading="lazy"
                        style="width: 100%; height: 100%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

</template>
<script scoped>
import sourceData from "@/data/about.json";
import gsap from 'gsap';
import aboutScrollingAnimation from '@/assets/js/scrolling-animation/aboutScrollingAnimation.js'

export default {
  name: "About",
  components: {},
  methods: {
    getImgUrlImageAbout: function (imagePath) {
      return require("@/assets/img/about/" + imagePath);
    },
  },
  mounted: function () {
    // Show all the page content during the transition
    // document.body.style.opacity = 1;

    // Project title animation when the component has been updated:
    var tl = gsap.timeline();
    tl.to(["#prjv0ezwr80WFOxs","#QUlUUmWnumAiQ7ml"], {duration: 1, opacity:1});
    tl.set(["#prjv0ezwr80WFOxs","#QUlUUmWnumAiQ7ml"], {className: "about__Text_Animate"});

    aboutScrollingAnimation.animation()

  },
  computed: {
    aboutData() {
      // console.log("sourceData.aboutDataPage =", sourceData.aboutDataPage)
      // console.log("sourceData.aboutDataPage.storiesList =", sourceData.aboutDataPage.storiesList)
      return sourceData.aboutDataPage;
    },
  },
};
</script>

