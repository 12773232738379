import imagesLoaded from 'imagesloaded'
//const charming = require('charming')
//import anime from 'animejs/lib/anime.es.js';

var aboutScrollingAnimation = {
    animation(){
        class Entry {
            constructor(el) {
                this.DOM = {el: el};
                observer.observe(this.DOM.el);
            }
            enter(direction) {
                if(direction){
                    const bodyEl = document.body;
                    // console.log("this.DOM.el = ", this.DOM.el)
                    // Change background color
                    bodyEl.style.backgroundColor = this.DOM.el.dataset.bgcolor;
                    // console.log("this.DOM.el =", this.DOM.el)
                }
            }
            exit(direction) {
                if(direction){
                    // const bodyEl = document.body;
                    // bodyEl.style.backgroundColor = this.DOM.el.dataset.bgcolor;
                }
            }
        }

        let observer;
        let current = -1;
        let allentries = [];
        const sections = Array.from(document.querySelectorAll('.about__section'));
        // Preload all the images in the page..
        imagesLoaded(document.querySelectorAll('.about--picture'), () => {
            // document.querySelector('.scrollingLettersAnimation5__demo-5').classList.remove('scrollingLettersAnimation5__loading');
            if ('IntersectionObserver' in window) {
                // document.querySelector('.scrollingLettersAnimation5__demo-5').classList.add('scrollingLettersAnimation5__ioapi');

                observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if ( entry.isIntersecting ) {
                            const newcurrent = sections.indexOf(entry.target);
                            // console.log("newcurrent entry =", entry.target)
                            // console.log("newcurrent =", newcurrent)
                            // console.log("current    =", current )
                            if ( newcurrent === current ) return;
                            const direction = newcurrent > current;
                            if (current >= 0 ) {
                                allentries[current].exit(direction ? 'down' : 'up');
                            }
                            allentries[newcurrent].enter(direction ? 'down' : 'up');
                            current = newcurrent;
                        }

                        // IMPORTANT: When target element is not longer visible
                        // We set the default background project color here
                        if (!entry.isIntersecting) {
                            // console.log("newcurrent entry exit =", entry.target)
                            const bodyEl = document.body;
                            const container = document.querySelector(".about__container")
                            if(container){
                                bodyEl.style.backgroundColor = container.dataset.bgcolor;
                            }
                        }
                    });
                }, { threshold: 0.3 });

                sections.forEach(section => allentries.push(new Entry(section)));
                // sconsole.log("sections = ", sections)
            }
        });
    }
}


export default aboutScrollingAnimation
